import LogoClothoff from "@/styles/image/logo-clothoff.svg";
import LogoDeepnudeai from "@/styles/image/logo-deepnudeai.svg";
import LogoNudiva from "@/styles/image/logo-nudiva.svg";
import LogoOkbra from "@/styles/image/logo-okbra.webp";

export function getMirrorsDataByHost(host: string) {
  const companyLower = host.replace(/^(beta\.|front\.|next\.|dev\.)/, "");

  const companyWaDomainZone = companyLower.replace(/\.(io|ai|com|net)$/, "");

  const company = companyLower.charAt(0).toUpperCase() + companyLower.slice(1);

  const isClothoff =
    host.includes("clothoff") || host.includes("localhost:3000");
  const isDeepnudeNET = host.includes("deepnudeai.net");
  const isDeepnudeIO = host.includes("deepnudeai.io");
  const isNudiva = host.includes("nudiva");
  const isOkbra = host.includes("okbra");
  const isNudecity = host.includes("nudecity");

  const getCompanyLogo = () => {
    if (isDeepnudeNET) {
      return LogoDeepnudeai;
    }
    if (isDeepnudeIO) {
      return LogoDeepnudeai;
    }
    if (isNudiva) {
      return LogoNudiva;
    }
    if (isOkbra) {
      return LogoOkbra;
    }
    return LogoClothoff;
  };

  const getJivoTag = () => {
    if (isDeepnudeNET) {
      return "EKfSSBWmpV";
    }
    if (isDeepnudeIO) {
      return "EKfSSBWmpV";
    }
    if (isNudiva) {
      return "4e0KXAjlT6";
    }
    return "hXAzxmaA8z";
  };

  const getCompanyAddress = () => {
    if (isNudiva) {
      return "AI Wrights Holdings Limited. Commerce House, Wickhams Cay 1, P.O. Box 3140, c/o Conyers Trust Company (BVI) Limited, Road Town, Tortola, VG1110, British Virgin Islands";
    }
    if (isNudecity) {
      return "QQQAI Limited, British Virgin Islands";
    }
    if (isClothoff) {
      return "AI/Robotics Venture Strategy 3 Ltd. 18 Pasea Estate Road, Road Town, Tortola, VG1110, British Virgin Islands";
    }
    return "";
  };

  const getCompanySupportEmail = () => {
    if (isOkbra) {
      return "okbra-support.astute788@passmail.net";
    }
    return `support@${companyLower}`;
  };

  const getCoopEmail = () => {
    if (isOkbra) {
      return "okbra.affection875@passmail.net";
    }
    return `cooperation@${companyLower}`;
  };

  const getMarketingEmailDomain = () => {
    if (isClothoff) {
      return "clothoff.email";
    }
    return "";
  };

  return {
    company,
    companyWaDomainZone,
    companyLower,
    isClothoff,
    isDeepnudeNET,
    isDeepnudeIO,
    isNudiva,
    isNudecity,
    isOkbra,
    companyLogo: getCompanyLogo(),
    jivoTag: getJivoTag(),
    supportEmail: getCompanySupportEmail(),
    coopEmail: getCoopEmail(),
    companyAddress: getCompanyAddress(),
    marketingEmailDomain: getMarketingEmailDomain(),
  };
}
