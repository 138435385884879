"use client";

import { PATH_RTA } from ".constants/links";
import Link from "next/link";

export const RtaLogo = () => (
  <Link href={PATH_RTA}>
    <svg
      width="79"
      height="27"
      viewBox="0 0 79 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V27H8V16H10L17 27H27L19 15C19 15 20.3107 14.5361 21 14C21.6893 13.4639 24 11 24 9C24 7.82843 24 7 24 7H32V27H41V7H52L44 27H53L54.5 23H63.5L65 27H74L64 0H0ZM8 5V11H14C14.5 11 15.1095 10.8905 15.5 10.5C15.8905 10.1095 16 10 16 9V7C16 6.5 15.9366 5.93662 15.5 5.5C15.0634 5.06338 14.5 5 14 5H8ZM58.5 7L56 17H61.5L58.5 7Z"
        fill="white"
      />
      <path
        d="M72.5057 17.5V11.6818H74.8011C75.2405 11.6818 75.6155 11.7604 75.9261 11.9176C76.2386 12.0729 76.4763 12.2936 76.6392 12.5795C76.804 12.8636 76.8864 13.1979 76.8864 13.5824C76.8864 13.9687 76.803 14.3011 76.6364 14.5795C76.4697 14.8561 76.2282 15.0682 75.9119 15.2159C75.5975 15.3636 75.2169 15.4375 74.7699 15.4375H73.233V14.4489H74.571C74.8059 14.4489 75.0009 14.4167 75.1562 14.3523C75.3116 14.2879 75.4271 14.1913 75.5028 14.0625C75.5805 13.9337 75.6193 13.7737 75.6193 13.5824C75.6193 13.3892 75.5805 13.2263 75.5028 13.0938C75.4271 12.9612 75.3106 12.8608 75.1534 12.7926C74.9981 12.7225 74.8021 12.6875 74.5653 12.6875H73.7358V17.5H72.5057ZM75.6477 14.8523L77.0938 17.5H75.7358L74.321 14.8523H75.6477Z"
        fill="white"
      />
      <circle cx="74.5" cy="14.5" r="4" stroke="white" />
    </svg>
  </Link>
);
