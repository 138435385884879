import { useLocale } from "next-intl";
import { StaticImageData } from "next/image";

import { langList } from "@/i18n";
import FlagAr from "@/styles/image/flags-new/ar.svg";
import FlagDe from "@/styles/image/flags-new/de.svg";
import FlagEn from "@/styles/image/flags-new/en.svg";
import FlagEs from "@/styles/image/flags-new/es.svg";
import FlagFr from "@/styles/image/flags-new/fr.svg";
import FlagHi from "@/styles/image/flags-new/hi.svg";
import FlagId from "@/styles/image/flags-new/id.svg";
import FlagIt from "@/styles/image/flags-new/it.svg";
import FlagJa from "@/styles/image/flags-new/ja.svg";
import FlagKo from "@/styles/image/flags-new/kr.svg";
import FlagPt from "@/styles/image/flags-new/pt.svg";
import FlagRu from "@/styles/image/flags-new/ru.svg";
import FlagTr from "@/styles/image/flags-new/tr.svg";
import FlagZh from "@/styles/image/flags-new/zh.svg";

export type LanguageCode =
  | "ar"
  | "de"
  | "en"
  | "es"
  | "fr"
  | "hi"
  | "id"
  | "it"
  | "ja"
  | "ko"
  | "pt"
  | "ru"
  | "tr"
  | "zh";

const flags: Record<LanguageCode, StaticImageData> = {
  ar: FlagAr,
  de: FlagDe,
  en: FlagEn,
  es: FlagEs,
  fr: FlagFr,
  hi: FlagHi,
  id: FlagId,
  it: FlagIt,
  ja: FlagJa,
  ko: FlagKo,
  pt: FlagPt,
  ru: FlagRu,
  tr: FlagTr,
  zh: FlagZh,
};

export const useLanguage = () => {
  const locale = useLocale();
  return langList.find((lang) => lang.key === locale)?.value;
};

export const useFlag = () => (lang: LanguageCode) => flags[lang];
