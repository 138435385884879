export const PORTALS = {
  ActivatePromocode: "ActivatePromocode",
  AnimatePhoto: "AnimatePhoto",
  AttachPhotoByInsta: "AttachPhotoByInsta",
  AttachPhotoByUrl: "AttachPhotoByUrl",
  BlockUser: "BlockUser",
  BlogFeedback: "BlogFeedback",
  BurgerMenu: "BurgerMenu",
  Captcha: "Captcha",
  ChangeLang: "ChangeLang",
  GenerationError: "GenerationError",
  GiftRoadmap: "GiftRoadmap",
  NewBalance: "NewBalance",
  NotificationSidebar: "NotificationSidebar",
  Passkey: "Passkey",
  PayHold: "PayHold",
  PWA: "PWA",
  ResetReUseFace: "ResetReUseFace",
  Rules: "Rules",
  SubscribeIncluded: "SubscribeIncluded",
  SubscribesFREE: "SubscribesFREE",
  SubscribesVIP: "SubscribesVIP",
  Tutor: "Tutor",
  TutorAnime: "TutorAnime",
  TutorBDSM: "TutorBDSM",
  TutorHD: "TutorHD",
  TutorPose: "TutorPose",
  UploadVideo: "UploadVideo",
};
