export const EC_COUNTRIES_LIST: string[] = [
  "AT", // Австрия
  "BE", // Бельгия
  "BG", // Болгария
  "CY", // Кипр
  "CZ", // Чехия
  "DE", // Германия
  "DK", // Дания
  "EE", // Эстония
  "ES", // Испания
  "FI", // Финляндия
  "FR", // Франция
  "GR", // Греция
  "HR", // Хорватия
  "HU", // Венгрия
  "IE", // Ирландия
  "IT", // Италия
  "LT", // Литва
  "LU", // Люксембург
  "LV", // Латвия
  "MT", // Мальта
  "NL", // Нидерланды
  "PL", // Польша
  "PT", // Португалия
  "RO", // Румыния
  "SI", // Словения
  "SK", // Словакия
];

export const API_MIN_AMOUNT = 50;

export const MIN_CHECK_REGEX = /.+@.+\..{2,}/;
export const VALID_EMAIL_REGEX =
  /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
